//
//
//
//
//
//
//
//
//
//
//
//

import MainView from "@/views/mainView/mainView.vue";
import loginApi from "@/api/login";
import systemApi from "@/api/system";
import comment from "@/components/comment/comment";
import CustomerInfo from "@/components/customerInfo/customerInfo.vue";
export default {
  name: "App",
  components: {
    MainView,
  },
  methods: {
    clickDescribe() {
      let that = this;
      let user = this.$store.state.currentUser;
      loginApi.checkLogin().then(() => {
        if (user.customerName == null || user.customerName == "") {
          this.$message.error("请先维护个人信息");
          this.showCustomerInfo();
          return;
        }

        this.isInComment = true;

        that.$layer.iframe({
          scrollbar: false,
          content: {
            content: comment, //传递的组件对象
            parent: that, //当前的vue对象
            data: {}, //props
          },
          shadeClose: false,
          // maxmin: true,//开启最大化最小化
          shade: false,
          resize: true, //是否允许拉伸，默认是不允许
          offset: [window.innerWidth - 250, window.innerHeight - 300],
          area: ["500px", "600px"],
          title: `留言`,
          cancel: () => {
            that.isInComment = false;
          },
        });
      });
    },
    /**
     * 个人信息面板
     */
    showCustomerInfo() {
      let that = this;
      that.$layer.iframe({
        scrollbar: false,
        content: {
          content: CustomerInfo, //传递的组件对象
          parent: that, //当前的vue对象
          data: {
            close: () => {
              that.$layer.closeAll();
            },
          }, //props
        },
        shadeClose: false,
        // maxmin: true,//开启最大化最小化
        shade: true,
        resize: false, //是否允许拉伸，默认是不允许
        area: ["610px", "640px"],
        title: `个人信息`,
        cancel: () => { },
      });
    },
    /**
     * 增加一次访问量计数
     */
    async addUvCount() {
      try {
        const uvMark = sessionStorage.getItem("uv_mark")
        if (uvMark) {
          return
        }
        await systemApi.addUvCount({
          type: "homepage"
        })
        sessionStorage.setItem("uv_mark", true);
      } catch (err) {
        console.log(err.message)
      }
    }
  },
  computed: {
    topClass() {
      if (this.$store.state.nav == 0) {
        return "top2";
      } else {
        return "top1";
      }
    },
    describeBtnVisible() {
      return this.$store.state.describeBtnVisible
    }
  },
  created() {
    this.addUvCount()
  },
};
