import request from '@/utils/request'
import qs from 'qs';

export function addUvCount(data) {
    return request({
        url: `/api/uv?${qs.stringify(data)}`,
        method: 'get'
    })
}

export default { addUvCount }