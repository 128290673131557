import Vue from 'vue'
import Router from 'vue-router'
import loginApi from '@/api/login'
import store from '@/store'
Vue.use(Router)

const constantRouterMap = [
    { path: '/', redirect: '/homePage' },
    { path: '/homePage', component: (resolve) => require(['@/views/homePage/homePage'], resolve), meta: { showDescribe:false,checkLogin: false, nav: 0, foot: 0 } },
    { path: '/cesiumMap', component: (resolve) => require(['@/views/cesiumMap/cesiumMap'], resolve), meta: { showDescribe:true,checkLogin: true, nav: 1, foot: 0 } },
    { path: '/registe', redirect: '/login' },
    { path: '/login', name: "login", component: (resolve) => require(['@/views/login/login'], resolve), meta: { showDescribe:false,checkLogin: false, nav: 0, foot: 0 } },
    { path: '/community', component: (resolve) => require(['@/views/community/index'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
    { path: '/yellowPage', component: (resolve) => require(['@/views/yellowPage/index'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
    // { path: '/example/activity', component: (resolve) => require(['@/views/example/activity/index'], resolve), },
    // { path: '/example/storeService', component: (resolve) => require(['@/views/example/storeService/index'], resolve), },
    // { path: '/example/shopBrand', component: (resolve) => require(['@/views/example/shopBrand/index'], resolve), },
    // { path: '/example/lightProject', component: (resolve) => require(['@/views/example/lightProject/index'], resolve), },
    // { path: '/example/cityBeauty', component: (resolve) => require(['@/views/example/cityBeauty/index'], resolve), },
    // { path: '/warning/law', component: (resolve) => require(['@/views/warning/law/index'], resolve), },
    // { path: '/warning/detection', component: (resolve) => require(['@/views/warning/detection/index'], resolve), meta: { checkLogin: true } },
    { path: '/detection', component: (resolve) => require(['@/views/detection/index'], resolve), meta: { showDescribe:true,checkLogin: true, nav: 1, foot: 0 } },
    // { path: '/about/company', component: (resolve) => require(['@/views/about/company/index'], resolve), },
    // { path: '/about/website', component: (resolve) => require(['@/views/about/website/index'], resolve), },
    { path: '/about', component: (resolve) => require(['@/views/about/index'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
    { path: '/innovative/group', component: (resolve) => require(['@/views/innovative/group/index'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
    // { path: '/innovative/newTech', component: (resolve) => require(['@/views/innovative/newTech'], resolve), },
    // { path: '/innovative/newCase', component: (resolve) => require(['@/views/innovative/newCase'], resolve), },
    // { path: '/customerInfo', component: (resolve) => require(['@/views/customerInfo/customerInfo'], resolve), meta: { checkLogin: false, nav: 0, foot: 0 } },
    { path: '/announce/term', component: (resolve) => require(['@/views/announce/term'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
    { path: '/announce/disclaimer', component: (resolve) => require(['@/views/announce/disclaimer'], resolve), meta: { showDescribe:true,checkLogin: false, nav: 1, foot: 0 } },
];

const router = new Router({
    mode: 'hash',
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
    if (to.path == '/login') {
        to.query.from = from.path;
    }

    if (to.meta && to.meta.showDescribe) {
        store.commit("setDescribeBtnVisible", to.meta.showDescribe);
    }else{
        store.commit("setDescribeBtnVisible", false);
    }

    if (to.meta && to.meta.checkLogin) {
        loginApi.checkLogin().then(res => {
            store.commit("setNav", to.meta.nav);
            store.commit("setFoot", to.meta.foot);
            next();
        }, err => {
            store.commit("setLoginRecPath", to.fullPath);
            next(false)
        })
    } else {
        store.commit("setNav", to.meta.nav);
        store.commit("setFoot", to.meta.foot);
        next();
    }
})

export default router;