import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        currentUser: null,
        webSocket: null,
        nav: 0,
        foot: 0,
        modalVisible: false,
        describeBtnVisible: false,
        loginRecPath: '/'
    },
    mutations: {
        setCurrentUser(state, currentUser) {
            state.currentUser = currentUser
        },
        setWebSocket(state, webSocket) {
            state.webSocket = webSocket
        },
        setNav(state, nav) {
            state.nav = nav
        },
        setFoot(state, foot) {
            state.foot = foot
        },
        setModalVisible(state, modalVisible) {
            state.modalVisible = modalVisible;
        },
        setDescribeBtnVisible(state, visible) {
            state.describeBtnVisible = visible;
        },
        setLoginRecPath(state, data) {
            state.loginRecPath = data
        }
    }
})

export default store;