import request from '@/utils/request'

export function getComment(params) {
    return request({
        url: `api/front/comment`,
        method: 'get',
        params
    })
}


export default { getComment }