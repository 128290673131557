//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBar from "@/components/nav-bar/nav-bar.vue";
import Footer from "@/components/footer/footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },

  computed: {
    getCurrentUser() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    particlesJS.load("particles", "/particles.json");
  },
  watch: {
    /**
     * websocket注册
     */
    getCurrentUser(newVal, oldVal) {
      if (newVal) {
        if (oldVal && oldVal.objectid == newVal.objectid) return;
        let ws = new WebSocket(
          `${[process.env.VUE_APP_WS_API]}/frontWebSocket/${newVal.objectid}`
        ); //连接服务器
        ws.onopen = function (event) {
          console.log("已经与服务器建立了连接...");
        };
        ws.onclose = function (event) {
          console.log("已经与服务器断开连接...");
        };
        this.$store.commit("setWebSocket", ws);
      } else {
        this.$store.state.webSocket.close();
      }
    },
  },

  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      window.location.reload();
      // this.isRouterAlive = false;
      // this.$nextTick(function () {
      //   this.isRouterAlive = true;
      // });
    },
  },
};
