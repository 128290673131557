//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commentApi from "@/api/comment.js";
export default {
  props: ["type", "item"],
  data() {
    return {
      commentLoading: false,
      ws: null,
      text: null,
      msgList: [

      ],
    };
  },
  mounted() {
    this.getMsgList();
    this.initWebSocket();
  },
  computed: {
    getCurrentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    getMsgList() {
      let that = this;
      this.commentLoading = true;
      let user = this.$store.state.currentUser;
      let data = {
        customerId: user.objectid,
      };
      commentApi.getComment(data).then((res) => {
        this.msgList = res.map((e) => {
          return { objectid: e.senderId, msg: e.content };
        });
        /**
         * 自动下拉到最底部
         */
        that.$nextTick(() => {
          const vel = that.$refs.wordsBox;
          vel.$el.scrollTop = vel.$el.scrollHeight;
        });
        this.commentLoading = false;
      });
    },
    initWebSocket() {
      if (this.ws) {
        console.log("ws exist");
      }
      let user = this.$store.state.currentUser;
      if (!user) {
        this.$message.error("访问受限，请登录！");
        this.$router.push({ path: "/login" });
        return;
      }
      if (!this.ws) {
        let that = this;
        try {
          let ws = this.$store.state.webSocket;
          ws.onmessage = function (event) {
            console.log("接收到服务器发送的数据..." + event.data);

            let msgData = JSON.parse(event.data);
            console.log(that.msgList);
            that.msgList.push(msgData);
            /**
             * 获取到最新消息后，自动下拉到最底部
             */
            that.$nextTick(() => {
              const vel = that.$refs.wordsBox;
              vel.$el.scrollTop = vel.$el.scrollHeight;
            });
            // document.getElementById("info").innerHTML += event.data + "<br>";
          };

          ws.onerror = function (event) {
            console.log("WebSocket异常！");
          };
          this.ws = ws;
        } catch (ex) {
          alert(ex.message);
        }
      } else {
        this.ws.close();
        this.ws = null;
      }
    },
    onEnterPress(event) {
      event.preventDefault();
      this.sendMsg();
    },
    sendMsg() {
      let user = this.$store.state.currentUser;
      let data = {
        content: this.text,
        senderId: user.objectid,
        receiverId: "0",
      };
      let json = JSON.stringify(data);
      this.wsSendData(json);
      this.text = null;
    },
    wsSendData(msg) {
      try {
        if (this.ws) {
          this.ws.send(msg);
        }
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
};
