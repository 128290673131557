//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import util from "@lib/util";
import { getUser, removeUser } from "@/utils/auth";
import loginApi from "@/api/login";
import CustomerInfo from "@/components/customerInfo/customerInfo.vue";

export default {
  data() {
    return {
      expand: false,
      currentMenuIndex: -1,
      currentChooseIndex: 0,
      scrollDirection: "down",
      pageScroll: 0,
      menuPanelShow: false,
      action: {
        logoShow: false,
        menuShow: false,
        loginShow: false,
      },
      menuMap: null,
      routers: ['/homePage', '/community', '/cesiumMap', '/detection', '/yellowPage', '/about']
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    navClass() {
      if (this.$store.state.nav == 0) {
        return "transparent-nav transparent-nav-expand";
      } else {
        return "white-nav white-nav-expand";
      }
    },
    getCurrentUser() {
      return this.$store.state.currentUser;
    },
    getNav() {
      return this.$store.state.nav;
    }
  },
  created() {
    this.handleScroll();
    let user = getUser();
    if (user) {
      this.$store.commit("setCurrentUser", JSON.parse(user));
    }
  },
  mounted() {
    let currenIndex = 0

    for (let i = 0; i < this.routers.length; i++) {
      if (window.location.hash.indexOf(this.routers[i]) > -1) {
        currenIndex = i
        break
      }
    }
    this.currentChooseIndex = currenIndex;
    this.action.logoShow = true;
    this.action.menuShow = true;
    this.action.loginShow = true;
  },
  methods: {
    routerClick(e) {
      this.currentChooseIndex = e;
    },
    logout() {
      this.shrinkSubMenu(6);
      loginApi.logout().then((res) => {
        if (res) {
          this.$store.commit("setLoginRecPath", this.$route.path);
          this.$router.push({ path: "/login" });
          removeUser();
          this.$store.commit("setCurrentUser", null);
        }
      });
    },

    // =====================动画效果==================
    logoEnter(el, done) {
      this.anime({
        targets: el,
        opacity: [0, 1],
        translateX: [-400, 0],
        easing: "easeOutCirc",
        duration: 1200,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    menuEnter(el, done) {
      this.anime({
        targets: el,
        translateX: [200, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 1200,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    loginEnter(el, done) {
      this.anime({
        targets: el,
        translateX: [200, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 1200,
        delay: 600,
        complete() {
          done();
        },
      });
    },
    handleMenu(flag, event) {
      if (!flag && event && event.toElement.className == "menu-item") {
        return;
      }
      this.shrinkSubMenu();
    },
    openMenuPanel() {
      this.menuPanelShow = true;
    },
    closeMenuPanel() {
      this.menuPanelShow = false;
    },
    handleScroll() {
      window.addEventListener("scroll", () => {
        let scrollTop = util.getPageScrollY();
        if (scrollTop > this.pageScroll) {
          this.scrollDirection = "down";
        } else {
          this.scrollDirection = "up";
        }

        this.pageScroll = scrollTop;
      });
    },
    expandMenu() {
      this.expand = true;
    },
    shrinkMenu() {
      this.expand = false;
    },
    maskShowAnime(el, done) {
      this.anime({
        targets: el,
        scaleY: [0, 1],
        opacity: [0, 1],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    maskHideAnime(el, done) {
      this.anime({
        targets: el,
        scaleY: [1, 0],
        opacity: [1, 0],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    menuPanelShowAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [-260, 0],
        easing: "linear",
        duration: 250,
        complete() {
          done();
        },
      });
    },
    menuPanelHideAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [0, -260],
        easing: "linear",
        duration: 250,
        complete() {
          done();
        },
      });
    },
    subMenuShowAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-30, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    subMenuHideAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [0, -30],
        opacity: [1, 0],
        easing: "linear",
        duration: 150,
        complete() {
          done();
        },
      });
    },
    expandSubMenu(index) {
      this.currentMenuIndex = index;
    },
    shrinkSubMenu() {
      this.currentMenuIndex = -1;
    },
    shrinkSubBigMenu(event, i) {
      if (
        event &&
        event.toElement &&
        (event.toElement.className == "search-menu" ||
          event.toElement.className == "search-menu-ul" ||
          event.toElement.className == "bg v-leave-active v-leave-to" ||
          event.toElement.className == "search-menu-li")
      ) {
        return;
      }
      this.$refs.searchMenu.reset();
      this.shrinkSubMenu(i);
    },
    // =====================动画效果==================
    showCustomerInfo() {
      let that = this;
      that.$store.commit("setModalVisible", true);
      that.$layer.iframe({
        scrollbar: false,
        content: {
          content: CustomerInfo, //传递的组件对象
          parent: that, //当前的vue对象
          data: {
            close: () => {
              that.$store.commit("setModalVisible", false);
              that.$layer.closeAll();
            },
          }, //props
        },
        shadeClose: false,
        // maxmin: true,//开启最大化最小化
        shade: true,
        resize: false, //是否允许拉伸，默认是不允许
        area: ["610px", "640px"],
        title: `个人信息`,
        cancel: () => {
          that.$store.commit("setModalVisible", true);
        },
      });
    },
    toLogin() {
      this.currentChooseIndex = 0
      this.$router.push('/login')
    }
  },
};
