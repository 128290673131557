import axios from 'axios'
import router from '@/routes'
import { Notification,Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: process.env.NODE_ENV === 'production' ? '/' : '/', // api 的 base_url
  timeout: 1200000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    // 兼容blob下载出错json提示
    if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
      const reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = function (e) {
        const errorMsg = JSON.parse(reader.result).message
        Notification.error({
          title: errorMsg,
          duration: 5000
        })
      }
    } else {
      let code = 0
      try {
        code = error.response.data.status
      } catch (e) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
          Notification.error({
            title: '网络请求超时',
            duration: 5000
          })
          return Promise.reject(error)
        }
      }
      code = error.response.status
      if (code) {
        if (code === 401) {
          Cookies.remove('ADVERTISING-DISPLAY-TOKEN')
          Cookies.remove('ADVERTISING-DISPLAY-USER')
          store.commit("setCurrentUser",null)
          Message.error('访问受限，请登录！')
          router.push({ path: '/login' })
        } else if (code === 403) {
          Cookies.remove('ADVERTISING-DISPLAY-TOKEN')
          Cookies.remove('ADVERTISING-DISPLAY-USER')
          store.commit("setCurrentUser",null)
          Message.error('访问受限，请登录！')
          router.push({ path: '/login' })
        } else {
          const errorMsg = error.response.data.message
          if (errorMsg !== undefined) {
            Message.error(errorMsg)
          }
        }
      } else {
        Notification.error({
          title: '接口请求失败',
          duration: 5000
        })
      }
    }
    return Promise.reject(error)
  }
)
export default service
