import request from '@/utils/request'
import qs from 'qs'

export function getCustomer(data) {
    return request({
        url: `api/front/customerInfo?${qs.stringify(data)}`,
        method: 'get'
    })
}


export function saveCustomer(data) {
    return request({
        url: `api/front/customerInfo`,
        method: 'post',
        data
    })
}

export function checkComment(params){
    return request({
        url: `api/front/customerInfo/checkComment`,
        method: 'post',
        params
    })
}

export default { getCustomer,saveCustomer }