import Vue from 'vue'
import App from './App.vue'
import router from '@/routes'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from '@/store'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
import particles from 'particles.js'
import '@/font/iconfont.css'
import * as Cesium from "cesium/Cesium.js";

/**
 * cesiumTdt 三维标记
 */
// window.Cesium = Cesium
// import "cesium//Widgets/widgets.css";
// import '@/lib'

Vue.use(particles)
Vue.prototype.$layer = layer(Vue);

Vue.use(ElementUI);

Vue.config.productionTip = false

//第三方库
import VueWebStorage from 'vue-web-storage'
import 'animate.css/animate.min.css'

//全局CSS
import '@/style/main.less'
import '@/style/index.less'

//启动配置文件
import boot from './config/boot'

boot.configAnime(); //配置Anime

//调用Vue插件
Vue.use(VueWebStorage, { prefix: 'tencent_fake_' });

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
