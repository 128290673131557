//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    footClass() {
      if (this.$store.state.foot == 0) {
        return "footer-realtive";
      } else {
        return "footer-absolute";
      }
    },
  },
  methods: {
    clickBeiAn() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
