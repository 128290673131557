//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerApi from "@/api/customer.js";
import HeadLogo from "@/components/headLogo/headLogo";
import Footer from "@/components/footer/footer";
import { getUser, setUser } from "@/utils/auth";
export default {
  props: {
    close: {
      type: Function,
    },
  },
  components: {
    HeadLogo,
    Footer,
  },
  mounted() {
    this.cusLoading = true;
    this.boardShow = true;
    customerApi.getCustomer().then((res) => {
      this.cusLoading = false;
      if (res) {
        this.form = {
          ...res,
        };
      }
    });
  },
  data() {
    return {
      cusLoading: false,
      submitLoading: false,
      boardShow: false,
      form: {
        objectId: null,
        phoneNum: null,
        customerName: null,
        companyName: null,
        companyAddress: null,
        email: null,
        city: null,
        qqId: null,
      },
      rules: {
        phoneNum: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        customerName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        city: [{ required: true, message: "请输入城市", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入公司名", trigger: "blur" },
        ],
        companyAddress: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        email: [{ required: false, message: "请输入邮箱", trigger: "blur" }],
        qqId: [{ required: false, message: "请输入qq号", trigger: "blur" }],
      },
    };
  },
  methods: {
    infoEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          console.log(this.form);
          customerApi.saveCustomer(this.form).then((res) => {
            this.submitLoading = false;
            this.$message({
              message: "提交成功",
              type: "success",
            });
            let form = JSON.parse(JSON.stringify(this.form));
            setUser(form, true);
            this.$store.commit("setCurrentUser", form);
            this.close();
            // this.$router.push({
            //   path: this.$route.query.from ? this.$route.query.from : "/",
            // });
          });
        } else {
          console.log("验证失败");
          return false;
        }
      });
    },
  },
};
