import request from '@/utils/request'
import qs from 'qs'

export function getRegisteCaptcha(params) {
  return request({
    url: 'api/front/registe/captcha',
    method: 'get',
    params
  })
}

export function getLoginCaptcha(params) {
  return request({
    url: 'api/front/login/captcha',
    method: 'get',
    params
  })
}

export function registe(data) {
  return request({
    url: 'api/front/registe',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: 'api/front/login/logout',
    method: 'get'
  })
}

export function login(data) {
  return request({
    url: 'api/front/login/login',
    method: 'post',
    data
  })
}

export function checkLogin(data) {
  return request({
    url: 'api/front/login/checkLogin',
    method: 'get',
  })
}


export default { registe,login,logout,checkLogin,getRegisteCaptcha,getLoginCaptcha }
